<template>
  <PageContent
    showMobileHeader
    :headerLabel="t('transaction_history')"
    class="transactions-content"
  >
    <EmptyState v-if="isEmpty" :title="t('transactions_empty_state.title')" />
    <div class="progress-container" v-if="isLoading && !errorMessage">
      <ProgressSpinner />
    </div>
    <div v-if="isPresent && !isLoading">
      <h1 class="settings-header h-1 d-none d-md-flex">
        {{ t('transaction_history') }}
      </h1>
      <div class="list">
        <div class="list-content">
          <ProfileCard
            v-for="(transaction, index) in transactions"
            :key="index"
            :transactionHistoryClient="transaction"
            :transactionDetails="transactionDetails"
            :errorMessage="errorMessage"
            :isRowBreak="checkIfBreak(transactions[index - 1], transaction)"
            @fetch-details="fetchTransactionDetails($event)"
          />
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import {
  PageContent,
  useResourceDataState,
  ProfileCard,
  transactionHistoryService,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/client/store'
import { TransactionHistoryDto } from '@bd/api'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import ProgressSpinner from 'primevue/progressspinner'
import { TransactionDetailsPayload } from '@bd/api/common/types/transaction'

export default defineComponent({
  name: 'ProfileTransactions',
  components: {
    PageContent,
    ProfileCard,
    EmptyState,
    ProgressSpinner,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const errorMessage = ref<string>()

    const checkIfBreak = (
      previous: TransactionHistoryDto | undefined,
      current: TransactionHistoryDto,
    ) => {
      if (!previous) {
        return true
      }
      const previousYear = new Date(previous.date).getFullYear()
      const currentYear = new Date(current.date).getFullYear()
      return previousYear !== currentYear
    }

    const transactions = computed(() => {
      return store.state?.transactionHistory?.transactions.content || []
    })
    const transactionDetails = computed(() => {
      return store.state.transactionHistory?.transactionDetails || []
    })

    const { isLoading, isPresent, isEmpty } = useResourceDataState(
      transactions,
      (res) => !!(res && res.length),
    )

    const fetchTransactions = async () => {
      await store.dispatch('transactionHistory/transactionsList')
    }

    const fetchTransactionDetails = async (
      payload: TransactionDetailsPayload,
    ) => {
      errorMessage.value = undefined
      try {
        await store.dispatch(
          'transactionHistory/transactionDetails',
          payload.offerId,
        )
      } catch (e) {
        const errorsData = transactionHistoryService.handleError(e)
        errorMessage.value = errorsData
      }
    }

    fetchTransactions()
    return {
      t,
      transactions,
      transactionDetails,
      checkIfBreak,
      isLoading,
      isPresent,
      isEmpty,
      fetchTransactionDetails,
      errorMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.transactions-content) {
  background-color: $alabaster;
}

.settings-header {
  width: 60%;
  align-items: center;
  min-height: 8rem;
  margin: 0 auto;
}
.list {
  background-color: $alabaster;
  margin: 0;
  &-content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @include breakpoint-up(md) {
      width: 60%;
    }
    @include breakpoint-down(md) {
      padding: 0 20px 60px 20px;
    }
  }
}
</style>
